.grecaptcha-badge {
    visibility: hidden;
}

.timer-inner {
    color: #ad9579;
    display: flex;
    padding: 0;
    border-radius: 5px;
}

.timer-segment {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.diver {
    padding: 0 2rem;
}

.swiper-slide.swiper-slide-visible.swiper-slide-active{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: start;
}

.swiper-slide.swiper-slide-next .cert, .swiper-slide.swiper-slide-prev .cert  {
    transform: translate(-50%) translateZ(100px) rotateY(90deg) !important;
    transition: opacity 2s ease, transform 2s ease !important;
    opacity: 0 !important;
}

.swiper-slide.swiper-slide-next .slideInfo, .swiper-slide.swiper-slide-prev .slideInfo  {
    height: 50px !important;
    color: rgba(0, 0, 0, 0) !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

.swiper {
    margin: 0 !important;
}

.swiper-slide.swiper-slide-prev {
    display: flex;
}

.swiper-slide.swiper-slide-next {
    display: flex;
}

.swiper-slide-shadow-coverflow {
    background-image: none !important;
}

.swiper-slide {
    width: 45vw;
    justify-content: center;
    align-content: center;
    align-items: start;
}

.slider .swiper-slide {
    width: auto;
}

.swiper-slide img {
    display: block;
    max-width: 79vw;
    max-height: 72vh;
}

.swiper-slide video {
    display: block;
    max-height: 72vh;
    max-width: 79vw;
}

.swiper-scrollbar {
    display: none;
}

.swiper-button-prev, .swiper-button-next {
    color: white;
}

.flex-item {
    width: 25%;
}
.flex-item-middle {
    width: 50%;
}

.roundNumber {
    width: 34px;
    height: 34px;
    left: calc(50% - 22px);
    position: absolute;
    bottom: 11px;
    line-height: 34px;
    font-weight: 600;
    font-size: 18px;
    color: #161C20;
    text-align: center;
    border: 2px solid #417aa4;
    border-radius: 50%;
}

.roundImg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    left: calc(50% - 18px);
    position: absolute;
    bottom: 15px;
}


.barinfo .roundImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    left: calc(50% - 25px);
    position: absolute;
    bottom: 35px;
}

.barinfo .roundNumber {
    width: 54px;
    height: 54px;
    left: calc(50% - 29px);
    position: absolute;
    bottom: 31px;
    line-height: 54px;
    font-weight: 600;
    font-size: 21px;
    color: #161C20;
    text-align: center;
    border: 2px solid #417aa4;
    border-radius: 50%;
}

@media (max-width: 1090px) {
    .flex-item, .flex-item-middle {
        width: 100%;
    }
}

@media (max-width: 635px) {
    .buttonTab {
        width: 100% !important;
        margin: 10px 0 !important;
    }
}

@media (max-width: 375px) {
    .swiper-slide img {
        display: block;
        max-height: 70vh;
        max-width: 70vw;
    }
}
